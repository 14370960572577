@import '../variables-common.scss';

/* colors */
$color-primary: #0033a1; // add primary company color here;
$color-accent: #0099a8; // add accent company color here;
$color-secondary: #0099a8; // add secondary company color here; if no secondary color specified use "lighten($color-accent, 10%)";
$color-badge: lighten($color-primary, 50%); // add badge color here for email template editing and viewing

/* background */
$main-background-color: $color-accent;
$main-background: linear-gradient(
  297deg,
  rgba($color-primary, 0.5) 0%,
  rgba($color-white, 0.7) 61%,
  rgba($color-accent, 0.3) 100%
); // adjust background here;

/* header logo */
$header-logo-width: 150px; // adjust header logo width here;
$header-logo-suffix-margin-top: 0; // adjust header logo suffix here;
